
















































import { defineComponent } from '@vue/composition-api'
import { useGetters } from '@u3u/vue-hooks'
import { formatPubDate, formatPubDateUS } from '@/inc/utils/date'
import InUse from '@/components/InUse.vue'
import FeaturedReferences from '@/components/FeaturedReferences.vue'
import BigCTA from '@/components/BigCTA.vue'
import SingleWrapper from '@/components/SingleWrapper.vue'

export default defineComponent({
  name: 'solution-single',
  components: { SingleWrapper, InUse, FeaturedReferences, BigCTA },
  setup() {
    const { content } = useGetters(['content'])

    return {
      content,
      formatPubDate,
      formatPubDateUS,
    }
  },
})
